jQuery.noConflict();

jQuery(document).ready(function () {

  var highest = 10;

  var mySwiper = new Swiper('.swiper-container', {
    // Optional parameters
    direction: 'horizontal',
    loop: false,

    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },

    /*on: {
      transitionStart: function(){
        
        var videos = document.querySelectorAll('video');
  
        Array.prototype.forEach.call(videos, function(video){
          video.pause();
        });
      },
      
      transitionEnd: function(){
        
        var activeIndex = this.activeIndex;
        var activeSlide = document.getElementsByClassName('swiper-slide')[activeIndex];
        var activeSlideVideo = activeSlide.getElementsByTagName('video')[0];
        activeSlideVideo.play();
      
      },
    
    }*/
  });
  jQuery('#slide1').css('z-index', ++highest);
  jQuery('.headvh__slidemenu').css('z-index', ++highest);
  jQuery('#header').css('z-index', 99999);
  jQuery('.menusmart').css('z-index', 99998);

  jQuery('#slide2').css("opacity","0");
  jQuery('#slide3').css("opacity","0");

  jQuery('#slide2 video').get(0).pause();
  jQuery('#slide2 video').get(0).currentTime = 0;
  jQuery('#slide3 video').get(0).pause();
  jQuery('#slide3 video').get(0).currentTime = 0;


  jQuery('#design').on("click", function () {
    if(! jQuery('#design').hasClass("active")){
      jQuery('#slide2 video').get(0).pause();
      jQuery('#slide3 video').get(0).pause();
      jQuery('#slide1 video').get(0).currentTime = 0;   
      jQuery('#slide1 video').get(0).play(); 
      jQuery('#slide1').css('z-index', ++highest);
      jQuery('.headvh__slidemenu').css('z-index', ++highest);
      jQuery('.menuhomeslider__item').removeClass("active");
      jQuery('#design').addClass("active");
      gsap.fromTo("#slide1",{opacity: 0}, {opacity: 1, duration: .5});
      gsap.fromTo("#slide1 .cartouche",{opacity: 0, x: -100, duration: 1}, {opacity: 1, x: 0, duration: .5});
    }
  });
  jQuery('#promo').on("click", function () {
    if(! jQuery('#promo').hasClass("active")){
      
    jQuery('#slide1 video').get(0).pause();
    jQuery('#slide3 video').get(0).pause();
    jQuery('#slide2').css('z-index', ++highest);
    jQuery('.headvh__slidemenu').css('z-index', ++highest);    
    jQuery('#slide2 video').get(0).currentTime = 0;   
    jQuery('#slide2 video').get(0).play(); 
    jQuery('.menuhomeslider__item').removeClass("active");
    jQuery('#promo').addClass("active");
    gsap.fromTo("#slide2",{opacity: 0}, {opacity: 1, duration: .5});
    gsap.fromTo("#slide2 .cartouche",{opacity: 0, x: -100, duration: 1}, {opacity: 1, x: 0, duration: .5});
    }
  });
  jQuery('#histo').on("click", function () {
    if(! jQuery('#histo').hasClass("active")){
      
      jQuery('#slide1 video').get(0).pause();
      jQuery('#slide2 video').get(0).pause();
      jQuery('#slide3 video').get(0).currentTime = 0;   
      jQuery('#slide3 video').get(0).play();       
    jQuery('#slide3').css('z-index', ++highest);
    jQuery('.headvh__slidemenu').css('z-index', ++highest);    
    jQuery('.menuhomeslider__item').removeClass("active");
    jQuery('#histo').addClass("active");
    gsap.fromTo("#slide3",{opacity: 0}, {opacity: 1, duration: .5});
    gsap.fromTo("#slide3 .cartouche",{opacity: 0, x: -100, duration: 1}, {opacity: 1, x: 0, duration: .5});
    }
  });

  jQuery(".itemmen").click(function () {
    jQuery(".itemmen").removeClass("open");
    jQuery(this).addClass("open");
  });



});